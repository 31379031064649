/*SideBar*/
.sideBarText {
	transform:rotate(90deg);
	color:#fff;
	transform-origin:20% 40%;
	font-weight:bold;
	font-size:3em;
	position:absolute;
	padding-top:.5em;
	padding-bottom:20px;
	width:4em;
}

.sideBarLinks {
	position: absolute;
	list-style-type: none;
	display: inline-flex;
	transform: rotate(90deg);
	color: #fff;
	transform-origin: 20% 40%;
	top: 22%;
	width: 5em;
	font-weight: bold;
	font-size: 1.7em;
	padding-bottom: 20px;
	padding-top: .8em;
}


.sideBarLinksLi {
	display: inline-flex;
	padding: 0px .5em;
	cursor: pointer;
}

.sidebar {
	z-index:5;
	grid-area: 1 / 1 / span 10 / span 1;
	border-right: 1px solid white;
	position: relative;
}

.mobile .icon {
	max-width: 30px;
	margin-top: 10px;
}


@media screen and (max-width: 1201px) and (min-width: 768px) {
  .sideBarText {
  	font-size: 2.25em;
  }
}

@media screen and (max-width: 767px) {
	.sideBarLinks {
		position: absolute;
		list-style-type: none;
		display: inline-flex;
		transform: rotate(90deg);
		color: #fff;
		transform-origin: 20% 40%;
		top: 10%;
		width: 5em;
		font-weight: bold;
		font-size: 1.7em;
		padding-bottom: 40px;
		padding-top: .8em;
		padding-right: 50px;
	}

	.sidebar {
		width: 45px;
	}
	  .sideBarLinksLi {
		display: inline-flex;
		padding: 0px 1.5em;
		cursor: pointer;
	}

}

@media screen and (max-width: 767px) and (min-width: 500px) {
	
  .sideBarText {
  	transform-origin: 15% 40%;
  	font-size: 1.75em;
  	padding: 0px;
  }
}

@media screen and (max-width: 499px)  {
  .sideBarText {
  	transform-origin: 15% 40%;
  	font-size: 1.75em;
  	padding: 0px;
  }
}