
.cardButton {
  font-size: 2em;
 	text-align: left;
 	font-weight: bold;
  padding-top: 15px;
/*  border-bottom: 3px solid white;
*/
}


.hightlighted {
  transition: all .4s ease-out;
  list-style-type: none;
  
}

.box img { 
  max-width: 100%;
}

.cardHeading {
  transition: all .4s ease-out;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
}

.box {
  overflow: scroll;
  transition: all 1s;
  opacity: 0;
  transform: translateX(100%);
  background-color: #1b1e21;
  grid-area: 1/15/span 10/ span 16;
  z-index: 5;
  color: #fff;
  padding: 3em;
}

.activeExample {
  opacity: 100;
  transform: translateX(0px);
  list-style-type: none;
}

.box .activeExample ul {
  list-style-type: none!important;

}

.activeExample::-webkit-scrollbar {
  display: none;
}

.activeContent .cardButton {
  border-bottom:3px solid white;

}

.activeContent > p {
  visibility: visible;
}

.activeContent .cardHeading {
  transform: translateX(30%);
}

#card1 {
	grid-area: 2/2/ auto/ span 6;
	z-index:2;
}

#card2 { 
	grid-area: 3/2/auto/ span 6;
	z-index: 3;
}

#card3 {
  grid-area: 4/2/auto/ span 6;
  z-index: 3;
}
#card4 {
  grid-area: 5/2/auto/ span 6;
  z-index: 3;

}
@media screen and (max-width: 1800px)  {

  .box {
    padding:10px;
  }


}

@media screen and (max-width: 1201px) and (min-width: 768px) {
  .cardButton {
    font-size: 1.5em!important; 
  }
}

@media screen and (max-width: 767px) {
  .activeContent .cardHeading {
    transform: translateX(20%);
  }

  /*.skills {
    display: grid;
    position: relative;
    top: auto;
    left: auto;
    grid-area: 5/1/span 4/span 5;
    font-size: .75em;
    padding-left: 10px;
  }

  .skills h2 {
    font-size: 1.5em;
  }

  .skills>ul{
    list-style-type: none;
    columns: 1;
    padding-left: 0px;
  }

  .skills>ul li {
    text-shadow: 1px 2px 2px purple;
    color: #fff;
    font-size: 1.25em;
    margin-left: 10px;
  }*/

}
@media screen and (max-width: 767px) and (min-width: 500px) {
  .cardButton {
    font-size: 1em;
  }

  .box {
    padding:4px;
  }
  .activeContent .cardHeading {
    transform: translateX(0%);
  }
  .box {
    grid-area: 1/2/span 7/ span 10;
    
    
  }

  .cardButton {
    font-size: 1.75em;
  }

  #card1 {
    grid-area: 8/2/ auto/ span 4;
  }

  #card2 {
    
    grid-area: 8/6/auto/ span 4;
  }

  #card3 {
    
    grid-area: 9/2/auto/ span 4;
    
  }

  #card4 {
    
    grid-area: 9/6/auto/ span 4;
    
  }

}

@media screen and (max-width: 499px)  {
  .activeContent .cardHeading {
    transform: translateX(0%);
  }
  .box {
    grid-area: 1/2/span 7/ span 10;
    
    
  }

  .cardButton {
    font-size: 1.4em;
  }

  #card1 {
    grid-area: 8/2/ auto/ span 4;
  }

  #card2 {
    
    grid-area: 8/7/auto/ span 4;
  }

  #card3 {
    
    grid-area: 9/2/auto/ span 4;
    
  }

  #card4 {
    
    grid-area: 9/7/auto/ span 4;
    
  }
}