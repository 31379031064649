/*need to break this down more*/
  .App {  
    font-family: sans-serif;  
    text-align: left; 
  } 

  #mycanvas { 
    position: fixed;
    z-index: 0;
  }

  body {
    margin:0px;
    padding:0px;
    background: #614385;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #516395, #614385);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #516395, #614385); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    z-index: 10;
  }

  a {
    color: #fff;
    font-weight: bolder;
   
  }

  a:hover {
    text-decoration: none;
    color:#222;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    cursor: pointer;
  }

  .container-fluid {
    padding: 0px!important;
  }

  .knockout {
    background: url(stars.jpg) -10px;
    color: black;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
    font-family: arial, helvetica;
    background-color: #000;
  }

  .gridContainer {
    grid-template-rows: repeat(10, 1fr [start]);
    display: grid;
    grid-auto-flow: dense;
    background-position: center;
    grid-template-columns: repeat(30, 1fr [start]);
    height: 100vh;
    overflow: hidden;
  }

/*@media screen and (max-width: 1599px) and (min-width: 1200px) {
  .introGridA {
    grid-area: 2 / 3 / span 4 / span 11!important;
    font-size: 80%;
  }
}*/

/*@media screen and (max-width: 1199px) and (min-width: 992px) {
  .introGridA {
    grid-area: 2 / 3 / span 3 / span 12!important;
    font-size: 70%;
  }

  .introGridB  {
    grid-area: 4/ 5 / span 2 / span 12!important;
    font-size:90%;
  }
}
*/
@media screen and (max-width: 991px) and (min-width: 768px) {

}

@media screen and (max-width: 767px) {
  .gridContainer {
    grid-gap: .5em;
    grid-template-rows: repeat(10, 1fr );
    padding: 0em;
    display: grid;
    grid-auto-flow: dense;
    background-position: center;
    grid-template-columns: repeat(10, 1fr );
  }
}


/*mobile styles for menu*/

#mobileMenu {  
 
  position: absolute;
  bottom: 0%;

}

svg {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.active svg {
  transform: rotate(90deg);
}

path {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
path:nth-child(1) {
  transform-origin: 36% 40%;
}
path:nth-child(2) {
  stroke-dasharray: 29 299;
}
path:nth-child(3) {
  transform-origin: 35% 63%;
}
path:nth-child(4) {
  stroke-dasharray: 29 299;
}
path:nth-child(5) {
  transform-origin: 61% 52%;
}
path:nth-child(6) {
  transform-origin: 62% 52%;
}
.active path:nth-child(1) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
.active path:nth-child(2) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(3) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(4) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(5) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(6) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}

