/* Config */
$color-particle: #fff;
$spacing: 2000px;
$time-1: 60s;
$time-2: 80s;
$time-3: 100s;
$time-4: 400s;


/* Pauls awesome mixin thanks Paul!*/
@function particles($max) {
   $val: 0px 0px $color-particle;
   @for $i from 1 through $max {
      $val: #{$val},
      random($spacing)+px random($spacing)+px ;
   }
   @return $val;
}

@mixin particles($max) {
   box-shadow: particles($max);

}
.particles > .particle {
  border-radius: 100%;
  background: transparent;
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.particle,
.particle:after {
 
  top: $spacing;
}

.particle:after {
  position:absolute;
  content: "";
  top: $spacing;

}

.particle-1 {
  animation: animParticleLoad $time-1 linear infinite alternate;
  @include particles(300);
  height:3px;
  width:3px;
  color: #fff;
}

.particle-1:after {
  @include particles(300);
  height:1px;
  width:1px;
}

.particle-2 {
  animation: reverseAnimParticle $time-2 linear infinite alternate;
  @include particles(200);
  height: 2px;
  width: 2px;
  color: #fff;
}

.particle-2:after {
  @include particles(100);
  height: 2px;
  width: 2px;
  border-radius: 100%;
}

.particle-3 {
  animation: reverseAnimParticle $time-3 linear infinite alternate;
  @include particles(100);
  height: 10px;
  width: 10px;
  border-radius: 100%;
  color: #fff;

}

.particle-3:after {
  @include particles(100);
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.particle-4 {
  animation: animParticle $time-4 linear infinite alternate;
  @include particles(400);
  height: 6px;
  width: 6px;
  border-radius: 100%;
}

.particle-4:after {
  @include particles(400);
  height:4px;
  width:4px;
}

.particle-5 {
  animation: reverseAnimParticle $time-1 linear infinite alternate;
  animation-delay: 20s;
  @include particles(600);
  height:1px;
  width:1px;
  border-radius: 100%;
 
}

.particle-5:after {
  @include particles(600);
  height:4px;
  width:4px;

}

@keyframes animParticleLoad {
  from { transform: translateX(-2000px); }
  to   { transform: translateX($spacing * -2); }
}

@keyframes animParticle {
  from { transform: translateX(-2000px); }
  to   { transform: translateX($spacing * -2); }
}
@keyframes reverseAnimParticle {
  from { transform: translateX(-2000px); }
  to   { transform: translateX($spacing * -.5); }
}

.page-wrapper {
    text-align: center;
    color: #fff;
    z-index: 0;
}



