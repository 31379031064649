.aboutText  {
	grid-area:1 /19 / span 10 / span 12;
	color:#fff;
	background-color:#1b1e21;
	z-index:100;
	padding:2em;
	font-size:1.5em;
	overflow: scroll;
}

.Abouthightlighted {
	color: #fff;
	font-size: 2em;
	
}

.aboutText ::-webkit-scrollbar {
  display: none;
}


.largeText {
	color:#9E9E9E;
	
 	font-weight:bold;
}

.skills {
	grid-area: 2/5/ span 4/ span 5;
	font-size: 1em;
	padding-left: 10px;
	z-index: 40;

}

.skills>ul{
  list-style-type: none;
  columns: 2;
}

.skills>ul li {
  color: #fff;
  font-size: 1.5em;
  margin-left: 10px;

}

@media screen and (max-width: 1800px) and (min-width: 768px) {
	.skills {
		grid-area:1/2/ span 4/ span 5;

	}
	.aboutText {
	    grid-area: 1 / 9 / span 10 / span 14;
	    padding:1em;
	    font-size: 1em;
    }

}
@media screen and (max-width: 767px) {


	.aboutText {
    grid-area: 1 / 3 / span 9 / span 8!important;
    padding:1em;
    font-size: 1em;
    
  }
}